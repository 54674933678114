.contenedor {
  margin: 0 auto;
}

.home_principal {
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2px;
  padding: 20px;
}

h1 {
  font-weight: 300;
  font-size: 2.5rem;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;

}

img {
  width: 100%;
  height: 300px;
  border-radius: 2px;
}

@media(min-width:480px) {
  .home_principal {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media(min-width:768px) {
  .home_principal {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media(min-width:980px) {
  .home_principal {
    grid-template-columns: repeat(5, 1fr);
  }
}

/* @media(min-width:1240px) {
  .home_principal {
    grid-template-columns: repeat(6, 1fr);
  }
} */