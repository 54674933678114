html {
  box-sizing: border-box;
  font-size: 62.5%;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  font-family: 'monserrat', sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
  background-image: linear-gradient(to top, #DFE9F3 0%, #ffffff 100%);
}

/**Globales**/

img {
  width: 100%;
  
  
}
.img_vision{
   width:100%;
   border-radius:10px;
   height:auto;
}
@media(min-width:768px){
   img{
  width: 100%;
  height:350px;
  
   }
   .img_vision{
   width:100%;
   border-radius:10px;
   height:350px;
}
}

.header {
  height: 50rem;
  background-image: url(../assets/vision.jpeg); 
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 2.5rem;
}

.contenedor-hero {
  position: absolute;
  /**Transpariencia**/
  background-color: rgba(17, 31, 39, 0.6);
  width: 100%;
  height: 100%;
}

.contenedor {
  padding-bottom: 5rem;
  width: 98%;
  margin: 0 auto;
}
.sombra{
  -webkit-box-shadow: 0px 5px 15px 0px rgba(112, 112, 112, 0.48);
  -moz-box-shadow: 0px 5px 15px 0px rgba(112, 112, 112, 0.48);
  box-shadow: 0px 5px 15px 0px rgba(112, 112, 112, 0.48);
  background-color:  #ffffff;
  padding: 1rem;
  border-radius: .5rem;
}

/**Utilidades**/

.fw-300 {
  font-size: 2rem;
}

h1 {
  font-family: 'monserrat', sans-serif;
  color: #0f0e0e;
  font-size: 5.8rem;
}

h2 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-family: 'montserrat', sans-serif;
  color: #0f0e0e;
  font-size: 1.7rem;
}

@media (min-width: 768px) {
  h2 {
      margin: 0 auto;
      font-family: 'montserrat', sans-serif;
      color: #0f0e0e;
      font-size: 3.4rem;
      font-weight: 400;
  }
}

h3 {
  margin: 0 auto;
  font-family: 'montserrat', sans-serif;
  color: #0f0e0e;
  font-size: 1.8rem;
}

h4 {
  color: #0f0e0e;
  font-size: 2.6rem;
}

p {
  margin-top: 0;
  font-size: 1.5rem;
}

.seccion {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.centrar-texto {
  text-align: center;
}

.fas {
  color: #ffffff;
  width: 2rem;
}

/**Grid**/

@media(min-width: 768px) {
  .contenedor h2 {
      font-family: 'montserrat'sans-serif;
      font-weight: 300;
      font-size: 2.5rem;
      margin-top: 0;
      margin-bottom: 0;
  }
}

.columnas-6 {
  flex: 0 0 calc(50% - 1rem);
}

.columnas-6-1 {
  height: 300px;
}

.columnas-6 p {
  font-family: 'montserrat'sans-serif;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.5rem;
}

@media(min-width: 768px) {
  .columnas-6 p {
      font-size: 1.5rem;
  }
}

.grid.columnas-6 h3 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.columnas-6 img {
  border-radius: 1rem;
}

/**encabezado**/

.header h1 {
  padding-top: 2rem;
  font-family: 'montserrat', sans-serif;
  color: #ffffff;
  font-weight: 300;
  font-size: 1.5rem;
  margin: 0;
  text-align: center;
}

.header a {
  text-decoration: none;
}

@media (min-width: 768px) {
  .header h1 {
      
      font-size: 4rem;
     
  }
}

.header p {
  font-family: 'montserrat';
  color: #ffffff;
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
  padding-bottom: 2.5rem;
  text-align: center;
}

@media (min-width: 768px) {
  .header p {
      font-family: 'montserrat'sans-serif;
      color: #ffffff;
      font-weight: 300;
      font-size: 2.5rem;
      margin: 0;
      padding-bottom: 2rem;
      text-align: center;
  }
}

i.fas.fa-map-marker-alt {
  margin-right: .4rem;
  color: #d81717
}

form {
  text-align: center;
  padding-bottom: 2rem;
}

.iconos {
  margin: .5rem;
}

@media (min-width:768px) {
  .iconos i {
      display: none;
  }
}

.iconos i {
  color: #d81717;
}

#nav {
  justify-content: space-between;
  ;
}

.navegacion-principal {
  background-color: #ff6c0038;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1;
}

@media (min-width: 768px) {
  .navegacion-principal {
      background-color: #ff8300b0;
      font-size: 1rem;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding: 1.5rem;
  }
}

@media (min-width: 920px) {
  .navegacion-principal {
      font-size: 1.2rem;
  }
}

@media (min-width: 1350px) {
  .navegacion-principal {
      font-size: 1.5rem;
  }
}

.navegacion-principal a {
  padding: .5rem;
  text-decoration: none;
}

.navegacion-principal a:hover {
  background-color: #ffc107;
  color: #212121;
  border-radius: 1.3rem;
}

@media(min-width: 768px) {
  .navegacion-principal a:hover {
      background-color: #ffc107;
      color: #212121;
      border-radius: 1.3rem;
  }
}

.far.fa-times-circle {
  color: #d81717;
}

@media (min-width: 768px) {
  .fa-times-circle {
      color: #DF7401;
  }
}

.cerrar {
  padding-bottom: 0;
}

.navegacion-principal a {
  color: #ffffff;
  text-decoration: none;
}

/*******************
 Redes Sociales
********************/

.site-footer {
  position: fixed;
  bottom:15px;
}

#contacto {
  padding-top: 1rem;
}
  .site-footer {
   position: fixed;
   right: 2rem;
   display: flex;
   font-size: 3rem;
   bottom: 1rem;
               }
  
@media (min-width: 768px) {
  .site-footer {
      right: 3rem;
      display: flex;
      font-size: 3rem;
      bottom: 15px;
  }
  #contacto {
      font-size: 1.7rem;
      padding: 1rem 0;
  }
}

.site-footer p {
  margin: 0 .5rem 0 0;
  font-size: 1.5rem;
  color: #23bfbf;
}

@media (min-width: 992px) {
  .site-footer p {
      margin-right: .5rem;
  }
}

.iconos-w i {
  font-size: 2.5rem;
  color: #3ead1293;
  margin-right: .3rem;
}

.iconos-f i {
  font-size: 2.5rem;
  margin-right: .3rem;
  color: #1399d893;
}

.fab.fa-instagram {
  font-size: 2.5rem;
  margin-right: .3rem;
  color: #d513d8b5;
}

@media (min-width: 768px) {
  .iconos-w i {
      font-size: 2.5rem;
      margin-left: .5rem;
      color: #3ead1293;
  }
  .iconos-f i {
      font-size: 2.5rem;
      margin-right: .5rem;
      color: #1399d893;
  }
}

/***********
   Pie de pagina
   ***********/

.copyright {
  padding-top: 1rem;
  background-color: black;
  padding-bottom: 1rem;
}

.copyright p {
  color: #23bfbf;
  text-align: center;
  font-family: 'montserrat' sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  padding-bottom: 2rem;
}

@media (min-width: 768px) {
  .copyright p {
      color: #23bfbf;
      text-align: center;
      font-family: 'montserrat'sans-serif;
      font-size: 1.7rem;
      font-weight: bold;
  }
}

/**Navegacion responsive**/

#nav:target {
  max-height: 85rem;
}

@media (min-width: 768px) {
  i, .cerrar {
      display: none;
  }
}

.mobile-menu {
  padding-bottom: 2rem;
  color: #ffffff;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
}

@media(min-width: 768px) {
  .mobile-menu {
      display: none;
      padding-bottom: 5rem;
  }
}

.mobile-menu p {
  padding-top: 1.2rem;
  padding-bottom: 0;
}

i.fas.fa-bars {
  padding-top: 1.5rem;
  color: #d81717
}

.barra {
  overflow: unset;
}

.barra {
  margin-top: 5rem;
}

@media (min-width: 768px) {
  .barra {
      margin-top: 15rem;
  }
}

/**Galeria de imagenes**/

.lista-fotografias {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: .5rem;
}

@media (min-width: 768px) {
  .lista-fotografias {
      grid-template-columns: repeat(5, 1fr);
  }
}

.fotografia {
  line-height: 0;
}

/**Paginas secundarias**/

/**Nosotros**/

.grid {
  display: grid;
  grid-gap: 1rem;
}

@media(min-width: 768px) {
  .grid {
     
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
  }
}

.contenido-nosotros {
  display: flex;
  justify-content: space-between;
}

.texto-nosotros {
  flex-basis: calc(50% - 1rem);
}

.iconos-nosotros {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
}

.fondo {
  background-color: #e7f1f1;
}

@media (min-width: 768px) {
  .contenedor h3 {
      text-align: center;
      font-weight: 300;
      font-size: 3rem;
      text-transform: uppercase;
  }
}

p {
  line-height: 1.5;
  text-align: justify;
  font-weight: 300;
}

.contactanos {
  color: #474444;
  font-family: 'montserrat'sans-serif;
  font-size: 3.5rem;
  margin: 0 auto;
  text-transform: uppercase;
}

.formulario-lleno {
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .formulario-lleno {
      font-size: 3.4rem;
  }
}

.contacto p {
  font-size: 1.5rem;
  color: #474444;
  margin: 2rem 0 0 0;
}

legend {
  text-align: center;
  font-size: 2rem;
  color: #474444;
}

legend.centrar-legend {
  text-align: center;
}

label {
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}

.boton {
       font-size: 2rem;
background-color: #ff8300;
color: #ffffff;
text-align: center;
border: none;
}

.boton:hover{
  background-color:#ffc107;
cursor:pointer;
}

/*Utilizar selector de atributos []**/

/**ademas para ahorra codigo se puede colocar una coma despues
del primer input y pegar el**/

input:not([type="submit"]), textarea, select {
  padding: 1rem;
  /**para que tome todo el espacio disponible**/
  display: block;
  /**para que mida el 100%**/
  width: 100%;
  /**color de fondo del texto**/
  background-color: #e1e1e1;
  /**Para separar todos los input**/
  margin-bottom: 2rem;
  /**quitar color de los bordes**/
  border: none;
  outline-style:none;
  /**y para quitar esquinas y colocarlas redondeadas se utiliza border-radius**/
  border-radius: none;
}

input[type="radio"] {
  width: auto;
  margin: 0;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

textarea {
  height: 20rem;
}

.contenedor-contacto {
  display: flex;
}

.foto-servicio {
  margin: 10px;
}

.foto-servicio img {
  border-radius: 10px;
}
.contenido-centrado{
text-align:center;
}
.contacto {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contacto_fieldset{
  width:100%;
}

@media(min-width:768px){
  
  .contacto{
      display:flex;
      justify-content:center;
      width:100%;
  
  }
  .contacto_fieldset{
  width:70%;
}
}



/**************************
       **************************
          REDES SOCIALES
       **************************
       **************************/